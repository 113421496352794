//
// Page Title(used by Dark Header layout only)
//

// Desktop mode
@include media-breakpoint-up(lg) {
    [data-kt-app-layout='dark-header'] {
        .app-header {
            .page-heading {
                color: $white !important;
            }
        }
    }
}
