//
// Scroll mixins
//

@mixin scrollbar-color($color, $hover-color: null) {
    // Firefox
    scrollbar-color: $color transparent;

    // Webkit
    &::-webkit-scrollbar-thumb {
        background-color: $color;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    @if ($hover-color != null) {
        // Hover state
        &:hover {
            // Firefox
            scrollbar-color: $hover-color transparent;

            // Webkit
            &::-webkit-scrollbar-thumb {
                background-color: $hover-color;
            }

            &::-webkit-scrollbar-corner {
                background-color: transparent;
            }
        }
    }
}
