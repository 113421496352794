.hidden {
    visibility: hidden;
}

.form-content {
    color: var(--kt-input-color);
    background-color: transparent;
    border: none;
    width: 100%;

    &[placeholder]:empty::before {
        content: attr(placeholder);
        color: var(--kt-input-placeholder-color);
    }

    &:focus {
        color: var(--kt-input-focus-color);
        outline: none;
    }


    &.form-content-heading {
        &.form-content-heading-xl {
            font-size: 3.6rem;
        }
        &.form-content-heading-h1 .ql-container, h1 {
            color: var(--kt-input-color);
            font-size: 2.4rem;
        }
        &.form-content-heading-h2 .ql-container, h2 {
            color: var(--kt-input-color);
            font-size: 2.2rem;
        }
        &.form-content-heading-h3 .ql-container, h3 {
            color: var(--kt-input-color);
            font-size: 2rem;
        }

        &.form-content-heading-xl, .ql-container {
            font-weight: bolder;
            &::-webkit-input-placeholder, &::-moz-placeholder, &::-ms-input-placeholder {
                font-weight: bold;
            }
        }
    }

    .ql-snow {
        border: 0;
    }

    .ql-editor {
        padding: 0;
    }
}

.content-block {
    position: relative;
    
    .content-toolbox {
        position: absolute;
        background-color: var(--kt-white);
        border: .1rem solid var(--kt-primary);
        height: 40px;
        margin-top: -45px;
        display: flex;

        .content-toolbox-item {
            min-width: 40px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: var(--kt-white);
            border: none;
            outline: 0;

            &.divider {
                border-left: .1rem solid var(--kt-primary);
            }

            &:hover, &.active, &.ql-active {
                background-color: var(--kt-primary);
                i, p {
                    color: #FFF;
                }
            }

            i, p {
                color: var(--kt-primary);
                font-size: 1.3rem;
                margin: 0;
            }
        }
    }
}